<template>
  <div class="login"></div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "Login",
});
</script>

<style scoped></style>
